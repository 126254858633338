import * as R from 'ramda'
import {Link} from 'gatsby'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const HashTagButtons = ({
  countries,
  industries,
  type,
  services,
  disabled,
  hasNoMargin,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const industryTags = industries && R.slice(0, 3, industries)
  const servicesTags = services && R.slice(0, 3, services)

  const renderCountriesTags = countries
    ? () =>
        mapIndexed(
          (tag, index) =>
            tag && (tag.name || tag.name.name) ? (
              <Link to={!disabled && `../#/${tag.name || tag.name.name}`}>
                <Button
                  className={classNames(classes.tag, classes.countrieTags, {
                    [classes.countrieTagsDisabled]: disabled,
                  })}
                  size="small"
                  key={index}
                >
                  #{tag.name || tag.name.name}
                </Button>
              </Link>
            ) : null,
          countries,
        )
    : null

  const renderIndustryTags = industryTags
    ? () =>
        mapIndexed(
          (tag, index) =>
            tag && (tag.name || tag.name.name) ? (
              <Link to={!disabled && `../#/${tag.name || tag.name.name}`}>
                <Button
                  className={classNames(classes.tag, classes.industrieTags, {
                    [classes.industrieTagsDisabled]: disabled,
                  })}
                  size="small"
                  key={index}
                >
                  #{tag.name || tag.name.name}
                </Button>
              </Link>
            ) : null,
          industryTags,
        )
    : null

  const renderTypeTags = tagType => (
    <Link to={!disabled && `../#/${tagType.name}`}>
      <Button
        className={classNames(classes.tag, classes.typeTags, {
          [classes.typeTagsDisabled]: disabled,
        })}
        size="small"
      >
        #{tagType.name}
      </Button>
    </Link>
  )

  const renderServicesTags = services
    ? () =>
        mapIndexed(
          (tag, index) =>
            tag && (tag.name || tag.name.name) ? (
              <Link to={!disabled && `../#/${tag.name || tag.name.name}`}>
                <Button
                  className={classNames(classes.tag, classes.serviceTags, {
                    [classes.serviceTagsDisabled]: disabled,
                  })}
                  size="small"
                  key={index}
                >
                  #{tag.name || tag.name.name}
                </Button>
              </Link>
            ) : null,
          servicesTags,
        )
    : null

  return (
    <div style={{margin: hasNoMargin && 0}} className={classes.tagsContainer}>
      {countries ? renderCountriesTags(countries) : ''}
      {industries ? renderIndustryTags(industries) : ''}
      {type ? renderTypeTags(type) : ''}
      {services ? renderServicesTags(services) : ''}
    </div>
  )
}

HashTagButtons.propTypes = {
  countries: R.is(Array),
  disabled: R.is(Boolean),
  industries: R.is(Array),
  services: R.is(Array),
  type: R.is(Object),
}

HashTagButtons.defaultProps = {
  countries: null,
  disabled: false,
  industries: null,
  services: null,
  type: null,
}

export default HashTagButtons
